<template>
  <div class="bmrInfo">
    <base-heading
      type="2"
      modifiers="centerDesktop"
      class="bmrInfo__title"
    >
      {{ $t('bodyBMRModal.title') }}
    </base-heading>

    <p class="bmrInfo__body bmrInfo__body--intro">
      {{ $t('bodyBMRModal.bodyIntro') }}
    </p>

    <p
      class="bmrInfo__body"
      v-html-safe="$t('bodyBMRModal.body')"
    />
  </div>
</template>

<script>
import BaseHeading from '@/components/BaseHeading';

export default {
  components: {
    BaseHeading,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.bmrInfo__body {
  @include small;

  @include desktop {
    margin-right: auto;
    margin-left: auto;
  }

  &--intro {
    margin: 0 0 2rem 0;

    @include desktop {
      text-align: center;
    }
  }
}
</style>
